<template>
	<ws-button
		is-small
		@click="toggleExpanded"
		:aria-label="$t('expand-collapse')"
	>
		<template #icon>
			<ws-icon
				icon="double-chevron"
				:rotate="expanded ? '180' : '0'"
				size="sm"
			/>
		</template>
	</ws-button>
</template>

<script>
export default {
	name: "SidebarExpandButton",

	props: {
		modelValue: {
			type: [Boolean, null],
			default: true
		}
	},
	emits: ["update:modelValue"],
	watch: {
		modelValue: function (_new) {
			this.expanded = _new;
		}
	},

	data() {
		return {
			expanded: this.modelValue
		};
	},

	methods: {
		toggleExpanded() {
			this.expanded = !this.expanded;
			this.$emit("update:modelValue", this.expanded);
		}
	}
};
</script>

<style lang="scss" scoped>
button {
	color: $grey;
	border-color: $grey;
	background-color: transparent;
	transition-property: backgrond-color, border-color;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 150ms;

	&:hover {
		border-color: $color-grey-700;
		color: $color-grey-700;
		background-color: $white;
	}

	&:focus,
	&.is-focused {
		box-shadow: none;
		border-color: $color-grey-300;
	}
}
</style>
