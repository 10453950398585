import { defineStore } from "pinia";
import { $log } from "@/plugins/logger/logger.plugin.js";
import { isDeviceUplinkActive } from "@/helpers/functions.helper.js";
import ConfigsService from "@/services/v1/Configs.service.js";
import ConnectorService from "@/services/v1/Connector.service.js";
import TasksService from "@/services/v1/Tasks.service.js";
import i18n from "@/lang/lang.js";
import helpers from "@/views/box/views/configuration/helpers/helpers";

const DEFAULT_BOX_VERSION = 1.3;
export const useDeviceStore = defineStore("device", {
	state: () => ({
		device: {},
		metrics: {},
		currentConfig: {},
		draftConfig: {},
		tasks: [],
		currentDataPointsCount: {},
		draftDataPointsCount: {},
		currentVirtualPropertiesDataPointsCount: {},
		draftVirtualPropertiesDataPointsCount: {},
		connectivity: {}
	}),
	getters: {
		deviceId: (state) => {
			return state.device?.deviceId;
		},

		deviceName: (state) => {
			if (Object.keys(state.device).length === 0) {
				return null;
			}

			return (
				state.device.name ||
				state.device.hardwareId ||
				state.device.deviceId
			).trim();
		},

		deviceVersion: (state) => {
			try {
				const version = Number(
					state?.device?.deviceVersionInfo?.hardware.trim().slice(-3)
				);
				if (Number.isNaN(version)) {
					return DEFAULT_BOX_VERSION;
				}
				return version;
			} catch (error) {
				$log.error("failed parsing device version", error);
				return DEFAULT_BOX_VERSION;
			}
		},

		/**
		 * Get APPs version of Box
		 *
		 * @return {Number|null} Return number of the version by concatenating all numbers (ex: 5.7.0 => 570; 5.9.2 => 592; ...)
		 */
		deviceApp: (state) => {
			let appVersion = state?.device?.deviceVersionInfo?.apps;
			if (!appVersion) {
				const softwareVersion =
					state?.device?.deviceVersionInfo?.software;
				// ex: v5.7.4-0-g3b1254e4-master -> ["v5.7.4", "5.7.4"]
				const regex = /^v(\d+.\d+.\d+)/gi;
				const resp = regex.exec(softwareVersion);
				if (resp && Array.isArray(resp) && resp[1]) {
					appVersion = resp[1];
				}
			}

			if (!appVersion) {
				throw new Error("no-app-version-in-box");
			}

			// 5.7.0 => 570
			return Number(appVersion.replace(/\./g, ""));
		},

		/**
		 * Get APPs version of Box
		 *
		 * @return {String} Return device APPS version
		 */
		deviceAppsVersion: (state) => {
			let appVersion = state?.device?.deviceVersionInfo?.apps;
			if (!appVersion) {
				const softwareVersion =
					state?.device?.deviceVersionInfo?.software;
				// ex: v5.7.4-0-g3b1254e4-master -> ["v5.7.4", "5.7.4"]
				const regex = /^v(\d+.\d+.\d+)/gi;
				const resp = regex.exec(softwareVersion);
				if (resp && Array.isArray(resp) && resp[1]) {
					appVersion = resp[1];
				}
			}

			return appVersion || "0.0.0";
		},

		deviceSubscription: (state) => {
			return state?.device?.subscription?.current?.ratePlanDetails
				?.ratePlan;
		},

		ratePlanType: (state) => {
			return state?.device?.subscription?.current?.ratePlanDetails?.type;
		},

		ratePlanRange: (state) => {
			return state?.device?.subscription?.current?.ratePlanDetails?.range;
		},

		hasHourlyBasedComputation: (state) => {
			return state?.device?.subscription?.current?.ratePlanDetails?.options?.includes(
				"HOURLY_BASED_COMPUTATION"
			);
		},

		hasAccessToHeatingOptmizer: (state) => {
			return state?.device?.subscription?.current?.ratePlanDetails?.options?.includes(
				"HEATING_OPTIMISER"
			);
		},

		hasControlRatePlan: (state) => {
			return (
				state?.device?.subscription?.current?.ratePlanDetails?.range ===
				"CONTROL"
			);
		},

		isDeviceOnline: (state) => {
			return state.connectivity.connectionStatusInfo?.status === "ONLINE";
		},

		deviceStatus: (state) => {
			return state.connectivity.connectionStatusInfo.status;
		},

		deviceUplink: (state) => {
			return state?.device?.connectivityStatus?.currentDataUplink;
		},

		currentDeviceUpLink: (state) => {
			return state.connectivity?.currentDataUplink;
		},

		requestedDeviceUplink(state) {
			return state.connectivity?.requestedDataUplink;
		},

		uplinkInProgress() {
			return this.requestedDeviceUplink?.status === "IN_PROGRESS";
		},
		// Is Device a Bridge/Hub ?
		isDeviceCloudHub: (state) => {
			return ["HUB", "BRIDGE"].includes(state.device.type);
		},

		cloudHubUplinkStatus() {
			return (
				this.isDeviceCloudHub &&
				isDeviceUplinkActive(this.currentDeviceUpLink)
			);
		},

		/** Output a list of issues to be displayed to user */
		deviceIssues() {
			const errors = [...Object.values(this.connectorsIssues)];

			return (errors.length > 0 && errors) || null;
		},

		hasAnyConnectorIssues() {
			return Object.values(this.connectorsIssues).length > 0;
		},

		/**
		 * Output a list of issues to be displayed to user
		 *
		 * @returns {object} Object where key is the connector and value is an array of strings (ex: {eth1: ["error 1", "error 2"]})
		 */
		connectorsIssues: (state) => {
			const errors = {};
			const connectorsBeingUsedByNetworks =
				state?.currentConfig?.networks
					?.map((network) => network.config?.physicalConnector)
					.filter((connector) => ["eth1", "eth2"].includes(connector))
					.filter(Boolean) || [];

			// check in metrics if there's a missing IP address from eth connector
			for (const [key, value] of Object.entries(state.metrics)) {
				if (!connectorsBeingUsedByNetworks?.includes(key)) {
					continue;
				}

				if (
					value?.payload?.link === "down" || // eth connector is down
					value === null || // no value
					(value?.payload?.link === "up" && // eth connector is set as up, but there's no IP address
						!value?.payload?.ip_address)
				) {
					errors[key] = [
						...(errors[key] || []),
						i18n.t("connector-canot-get-ip-address", {
							connector: key
						})
					];
				}
			}

			return errors;
		},

		oldRvlEquipments: (state) => {
			if (!state?.currentConfig?.equipments) {
				return [];
			}

			const rvlEquips = state?.currentConfig?.equipments
				.filter((eq) => eq?.config?.protocol === "LPB")
				.map((eq) => eq.equipmentId)
				.reduce((acc, equipmentId) => {
					if (!equipmentId) {
						return { ...acc };
					}
					return {
						...acc,
						[equipmentId]: -1
					};
				}, {});

			state?.currentConfig?.properties.forEach((prop) => {
				// if first property of equipment
				if (rvlEquips[prop.equipmentId] === -1) {
					rvlEquips[prop.equipmentId] = 0;
				}
				// if it has kind => increment count
				if (prop.kind && rvlEquips[prop.equipmentId] >= 0) {
					++rvlEquips[prop.equipmentId];
				}
			});

			const equipmentsWithMigrationTask = [];
			state.tasks.forEach((task) => {
				if (task.type?.type === "RVL_MIGRATION") {
					equipmentsWithMigrationTask.push(task.type?.equipmentId);
				}
			});

			const noKindsRvl = [];
			Object.keys(rvlEquips).forEach((equipmentId) => {
				if (
					rvlEquips[equipmentId] === 0 &&
					// !equipmentsWithMigrationTask.includes(equipmentId)
					!equipmentsWithMigrationTask.length
				) {
					noKindsRvl.push(equipmentId);
				}
			});

			return noKindsRvl;
		},

		deviceMetrics: (state) => {
			return state.metrics;
		},

		deviceConfigOrigin: (state) => {
			return state.device.deviceConfigOrigin;
		},
		gsmMetrics: (state) => {
			return state.connectivity.modem;
		},
		ethMetrics: (state) => {
			return {
				eth0: state.connectivity.eth0,
				eth1: state.connectivity.eth1
			};
		},
		ethConnected() {
			return [
				this.ethMetrics.eth0?.payload.link,
				this.ethMetrics.eth1?.payload.link
			].includes("up");
		},
		wwanUp: (state) => {
			return (
				state.connectivity.wwan0?.payload.link === "up" &&
				!!state.connectivity.wwan0?.payload.ip_address
			);
		},
		hasCurrentConfig: (state) => {
			return Object.keys(state.currentConfig).length !== 0;
		},
		hasDraftConfig: (state) => {
			return Object.keys(state.draftConfig).length !== 0;
		},
		hasEquipementWithPropertiesDrafted: (state) => (equipmentId) => {
			const currentProperties =
				state.currentConfig?.properties?.filter(
					(prop) => prop.equipmentId === equipmentId
				) || [];
			const draftProperties =
				state.draftConfig?.properties?.filter(
					(prop) => prop.equipmentId === equipmentId
				) || [];
			return helpers
				.mergeCurrentAndDraftObject(
					currentProperties,
					draftProperties,
					"propertyId"
				)
				.some((prop) => prop.__status === "edited");
		}
	},
	actions: {
		resetAllStates() {
			this.device = {};
			this.metrics = {};
			this.currentConfig = {};
			this.currentDataPointsCount = {};
			this.draftDataPointsCount = {};
		},

		setConnectivity(payload) {
			this.connectivity = payload;
		},

		setMetric(payload) {
			this.metrics = {
				...this.metrics,
				...payload
			};
		},

		setTasks(payload) {
			this.tasks = [...payload];
		},

		setCurrentConfiguration(payload) {
			this.currentConfig = payload;
		},

		// payoad={revision: "current|draft", data:{current,max,hourlyBasedComputation,...}}
		setDataPointsCount(payload) {
			if (payload?.revision === "current") {
				this.currentDataPointsCount = { ...payload?.data };
			} else if (payload?.revision === "draft") {
				this.draftDataPointsCount = { ...payload?.data };
			}
		},

		/**
		 *
		 * @param {string} state draft | current
		 * @param {object} payload {current: number, max: number}
		 */
		setVirtualPropertiesDataPointsCount(payload) {
			if (payload?.revision === "current") {
				this.currentVirtualPropertiesDataPointsCount = {
					...payload?.data
				};
			} else if (payload?.revision === "draft") {
				this.draftVirtualPropertiesDataPointsCount = {
					...payload?.data
				};
			}
		},

		setCurrentNetworkConfiguration(payload) {
			this.currentConfig.networks = payload;
		},
		setConnectivityStatus(payload) {
			this.connectivity = {
				...this.connectivity,
				requestedDataUplink: {
					...this.connectivity?.requestedDataUplink,
					status: payload
				}
			};
		},
		setConnectivityCommand(payload) {
			this.connectivity = {
				...this.connectivity,
				requestedDataUplink: {
					...this.connectivity?.requestedDataUplink,
					command: payload
				}
			};
		},
		// actions
		reset() {
			this.resetAllStates();
		},
		setDevice(device) {
			this.device = device;
			if (Object.keys(device || {}).length > 0) {
				this.getTasks();
				this.getCurrentConfiguration();
				this.getEthernetConnectorsIpAddress();
				this.getDataPointsCount("draft");
				this.getDataPointsCount("current");
				this.getVirtualPropertiesDataPointsCount("draft");
				this.getVirtualPropertiesDataPointsCount("current");
			}
		},

		// @TODO: remove after rework all-in-one function setDevice
		setDeviceOnly(device) {
			this.device = device;
		},

		setConnectivityRunning(payload) {
			this.setConnectivityStatus(payload.status);
			this.setConnectivityCommand(payload.command);
		},

		getTasks: async function () {
			try {
				const tasks = await TasksService.getTasks(this.deviceId);
				this.setTasks(tasks);
				return tasks;
			} catch (error) {
				$log.info("Failed getting tasks on vuex");
				this.setTasks([]);
			}
		},

		async getCurrentConfiguration() {
			try {
				const config = await ConfigsService.getCurrent(this.deviceId);
				this.setCurrentConfiguration(config);
				return config;
			} catch (error) {
				$log.info("Failed getting current config on vuex");
				this.setCurrentConfiguration({});
			}
		},

		getEthernetConnectorsIpAddress: async function () {
			try {
				let connectors = await ConnectorService.getAllFromRevision(
					"current",
					this.deviceId
				);

				// check if there's an eth connector. If so, check if any of it is set to "dhcp" method to request it's IP address
				connectors = connectors.filter(
					(connector) =>
						["eth1", "eth2"].includes(
							connector.config.physicalConnector
						) && connector?.config?.ipv4?.method === "dhcp"
				);

				for (const connector of connectors) {
					const physicalConnector =
						connector.config.physicalConnector;

					/**
					 * embedded use eth0 and eth1 (while API uses eth1 and eth2)
					 * API  | embedded
					 * eth1 | eth0
					 * eth2 | eth1
					 */
					const embeddedConnector = `eth${(
						Number(physicalConnector.split("eth")[1]) - 1
					).toString()}`;
					const metric = this.connectivity[embeddedConnector];

					if (!metric) {
						continue;
					} // when failed getting metrics, like a 404 status
					this.setMetric({
						[physicalConnector]:
							(metric && {
								timestamp: metric.timestamp,
								payload: {
									link: metric.payload.link,
									netmask: metric.payload.netmask,
									ip_address: metric.payload.ip_address,
									broadcast: metric.payload.broadcast
								}
							}) ||
							null
					});
				}
			} catch (err) {
				if (err?.status !== 404) {
					$log.error("Failed getEthernetConnectorsIpAddress", err);
				}
			}
		},

		queueRvlMigrationTasks: async function (rvlEquipments) {
			if (!rvlEquipments.length) {
				return false;
			}
			try {
				await TasksService.queueRVLMigrationTask(
					rvlEquipments[0],
					this.deviceId
				);
				this.getTasks();
				return true;
			} catch (err) {
				$log.error("Failed queueing RVL Migration task", err);
				return false;
			}
		},

		getDataPointsCount: async function (revision) {
			try {
				const count = await ConfigsService.getDataPointsCount(
					this.deviceId,
					revision
				);
				this.setDataPointsCount({ revision, data: count });
				return count;
			} catch (error) {
				$log.info("Failed getting data points count on vuex");
				this.setDataPointsCount({ revision, data: {} });
			}
		},

		getVirtualPropertiesDataPointsCount: async function (revision) {
			try {
				const count =
					await ConfigsService.getVirtualPropertiesDataPointsCount(
						this.deviceId,
						revision
					);
				this.setVirtualPropertiesDataPointsCount({
					revision,
					data: count
				});
				return count;
			} catch (error) {
				$log.info(
					"Failed getting virtual properties data points count on vuex"
				);
				this.setVirtualPropertiesDataPointsCount({
					revision,
					data: {}
				});
			}
		},

		async getDraftConfig() {
			try {
				const draftConfiguration = await ConfigsService.getDraft(
					this.deviceId
				);
				this.draftConfig = draftConfiguration;

				return draftConfiguration;
			} catch (err) {
				if (err?.status === 404) {
					await this.createDraftConfig();
				} else {
					$log.error(i18n.t("failed-get-draft-config"));
				}
			}
		},
		async createDraftConfig() {
			try {
				const draftConfiguration = await ConfigsService.createDraft(
					this.deviceId
				);
				this.draftConfig = draftConfiguration;

				return draftConfiguration;
			} catch (err) {
				$log.error(i18n.t("failed-creating-draft-refresh-page"));
			}
		},
		async deleteDraftConfig() {
			try {
				await ConfigsService.deleteDraft();
				this.draftConfig = {};
			} catch (err) {
				$log.error(
					`${i18n.t(
						"views.box.views.configuration.components.footer-buttons.failed-reverting-changes"
					)}: ${err.data.message}`
				);
			}
		}
	}
});
