import dayjs from "dayjs";

import "dayjs/locale/en";
import "dayjs/locale/fr";
import "dayjs/locale/de";

// to use formats like "L LT"
import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(localizedFormat);

// to use .from()
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

// to use formats like Do ....
import advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(advancedFormat);

// to use dayjs(date, "some format ex: 'x'")
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

// to use utc & utcOffset
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);

// to use dayOfYear
import dayOfYear from "dayjs/plugin/dayOfYear";
dayjs.extend(dayOfYear);

// to update a locale's properties.
import updateLocale from "dayjs/plugin/updateLocale";
dayjs.extend(updateLocale);

// to add custom Wattsense formats
import wsDateFormats from "./ws-date-formats.js";
dayjs.extend(wsDateFormats);

import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(isSameOrAfter);

import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrBefore);

import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);

export const $dayjs = function (...args) {
	return dayjs(...args);
};
export const $formatDate = function (text = "", format = "L HH:mm:ss") {
	return dayjs(text).format(format);
};

export const useDayjs = () => ({
	$dayjs
});

export default {
	install(app) {
		app.config.globalProperties.$dayjsSetLocale = function (locale = "en") {
			dayjs.locale(locale);
		};
		app.config.globalProperties.$dayjs = $dayjs;
		app.config.globalProperties.$formatDate = $formatDate;
	}
};
