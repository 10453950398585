<template>
	<div role="navigation" aria-label="main navigation">
		<!-- Mobile -->
		<div class="sidebar-mobile is-hidden-tablet">
			<sidebar-mobile
				:show-menu="showMobileSidebar"
				@toggle-show-menu="toggleMenuMobile"
			>
				<sidebar-items :is-expanded="true" />
			</sidebar-mobile>
		</div>
		<!-- Desktop -->
		<div class="sidebar is-hidden-mobile" :class="{ expanded: expanded }">
			<sidebar-items
				:is-expanded="expanded"
				@toggle-expansion="toggleExpansion"
			/>
		</div>
	</div>
</template>

<script>
import AuthMixin from "@/mixins/auth.mixin.js";
import SidebarItems from "./sidebar-items.vue";
import SidebarMobile from "./sidebar-mobile.vue";
import Eventbus from "@/eventbus.js";

export default {
	name: "Sidebar",

	mixins: [AuthMixin],

	created() {
		Eventbus.$on("toggleSidebarMenu", () => {
			this.showMobileSidebar = !this.showMobileSidebar;
		});
		if (localStorage.getItem("sidebarExpanded") === "false") {
			this.expanded = false;
		}
	},

	unmounted() {
		Eventbus.$off("toggleSidebarMenu", () => {
			this.showMobileSidebar = !this.showMobileSidebar;
		});
	},

	data() {
		return {
			showMobileSidebar: false,
			expanded: true
		};
	},

	methods: {
		toggleExpansion() {
			this.expanded = !this.expanded;
			window.dispatchEvent(new Event("resize"));
			localStorage.setItem(
				"sidebarExpanded",
				this.expanded ? "true" : "false"
			);
		},

		toggleMenuMobile() {
			this.showMobileSidebar = !this.showMobileSidebar;
		}
	},

	components: {
		SidebarItems,
		SidebarMobile
	}
};
</script>

<style lang="scss" scoped>
.sidebar.expanded,
.sidebar-mobile {
	max-width: 217px;
}

.sidebar {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	height: 100%;
	width: 100%;
	max-width: 75px;
	border-right: 1px solid $color-grey-300;

	&.expanded {
		hr {
			margin: 1.5rem;
		}
	}
}
</style>
