import AuthService from "@/services/Auth.service.js";

export default {
	computed: {
		$_user() {
			return AuthService.user();
		},

		loggedUser() {
			return AuthService.user();
		},

		/**
		 * Is user demo account
		 * @return {Boolean}
		 */
		isDemo() {
			return AuthService.isDemo();
		},

		/**
		 * Is user demo account
		 * @return {Boolean}
		 */
		$_isDemo() {
			return AuthService.isDemo();
		},

		/**
		 * Is user a Wattsense user
		 * [ wattsense email and not demo account ]
		 * @return {Boolean}
		 */
		$_isWattsense() {
			return AuthService.isWattsense();
		},

		/**
		 * Is user admin
		 * @return {Boolean}
		 */
		isAdmin() {
			return AuthService.isAccountAdmin();
		},

		/**
		 * Is user read only on all his organizations
		 * @return {Boolean}
		 */
		$_isReadOnlyUser() {
			return AuthService.isReadOnlyUser();
		}
	}
};
