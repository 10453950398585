<template>
	<div class="sidebar-items">
		<router-link
			to="/"
			class="logo"
			:aria-label="$t('sidebar-menu-item.home')"
			v-tooltip="{
				disabled: isExpanded,
				content: $t('sidebar-menu-item.home')
			}"
		>
			<img :src="getLogoImg" :alt="`logo - ${$clientName}`" />
		</router-link>

		<div class="items">
			<div>
				<nav>
					<sidebar-item
						icon="location"
						:to="{ name: 'boxes-map' }"
						:is-expanded="isExpanded"
					>
						{{ $t("sidebar-menu-item.map") }}
					</sidebar-item>
					<sidebar-item
						data-testid="boxes-link"
						icon="tower"
						:to="{ name: 'boxes' }"
						:is-expanded="isExpanded"
						:is-active="
							isRouteActiveByRouteName(['boxes', 'box-v1', 'box'])
						"
					>
						{{ $t("client-name-boxes") }}
					</sidebar-item>
					<sidebar-item
						data-testid="hubs-link"
						icon="bridge"
						:to="{ name: 'hubs' }"
						:is-expanded="isExpanded"
						:is-active="
							isRouteActiveByRouteName(['hubs', 'hub-v1'])
						"
					>
						{{ $t("client-name-hubs") }}
					</sidebar-item>
					<sidebar-item
						data-testid="alarms-link"
						icon="bell"
						:to="{ name: 'alarms' }"
						:is-expanded="isExpanded"
					>
						{{ $t("alarms") }}
					</sidebar-item>
					<hr v-if="!$_isReadOnlyUser" />
					<sidebar-item
						v-if="!$_isReadOnlyUser"
						icon="files"
						:to="{ name: 'file-resources-management' }"
						:is-expanded="isExpanded"
					>
						{{ $t("views.file-resources-management.title") }}
					</sidebar-item>

					<sidebar-item
						v-if="!$_isReadOnlyUser"
						icon="organization"
						:to="{ name: 'organization-view' }"
						:is-expanded="isExpanded"
					>
						{{ $t("sidebar-menu-item.company") }}
					</sidebar-item>
				</nav>
			</div>
		</div>
		<div class="items-bottom is-hidden-mobile">
			<sidebar-expand-button
				:value="isExpanded"
				@update:model-value="$emit('toggle-expansion')"
				:aria-label="
					isExpanded
						? $t('sidebar-menu-item.collapse-sidepanel')
						: $t('sidebar-menu-item.expand-sidepanel')
				"
				v-tooltip="{
					content: isExpanded
						? $t('sidebar-menu-item.collapse-sidepanel')
						: $t('sidebar-menu-item.expand-sidepanel')
				}"
			/>
		</div>
	</div>
</template>

<script>
import SidebarItem from "./sidebar-item.vue";
import SidebarExpandButton from "./sidebar-expand-button.vue";
import AuthMixin from "@/mixins/auth.mixin.js";
import {
	getBigLogoUrl,
	getSmallLogoUrl
} from "@/plugins/client-name/client-name.plugin.js";

export default {
	name: "SidebarItems",

	mixins: [AuthMixin],

	props: {
		isExpanded: {
			type: Boolean,
			default: true
		}
	},

	emits: ["toggle-expansion"],
	computed: {
		getLogoImg() {
			return this.isExpanded ? this.largeLogoUrl : this.smallLogoUrl;
		}
	},

	data() {
		return {
			largeLogoUrl: null,
			smallLogoUrl: null
		};
	},

	async mounted() {
		this.largeLogoUrl = await getBigLogoUrl();
		this.smallLogoUrl = await getSmallLogoUrl();
	},

	methods: {
		/**
		 * Check if current route matches any of the routeNames (when visiting a child route, the matched routes will return all routes from parent to current child route)
		 *
		 * @param {array|string} routeNames Route names as defined on routes file
		 */
		isRouteActiveByRouteName(routeNames) {
			let routes = routeNames;
			if (typeof routeNames === "string") {
				routes = [routeNames];
			}

			const routeNamesMatchedFromCurrentRoute =
				this.$route?.matched?.flatMap(
					(routeMatched) => routeMatched?.name
				);
			const isActive = routeNamesMatchedFromCurrentRoute.some(
				(routeMatched) => routes.includes(routeMatched)
			);
			return isActive;
		}
	},

	components: {
		SidebarItem,
		SidebarExpandButton
	}
};
</script>

<style lang="scss" scoped>
.sidebar-items {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	background: $color-grey-100;
	overflow-y: auto;
	padding-top: 0;
	padding-bottom: 1rem;

	.logo {
		display: flex;
		align-items: center;
		flex-shrink: 0;
		padding: 0 1rem;

		& > img {
			width: auto;
			height: $navbar-height;
		}
		&:focus-visible {
			outline: 2px auto $focus-outline-color;
			outline-offset: -2px;
		}
	}

	.items {
		flex: 1 1 0%;
		flex-direction: column;
		overflow-y: auto;
		margin-top: 1rem;

		& > div {
			overflow-y: auto;

			& > nav {
				padding: 0 0.75rem 0.25rem 0.75rem;

				a {
					margin-top: 0.25rem;
				}
			}
		}

		hr {
			background-color: $color-grey-400;
			margin: 0.5rem;
			height: 1px;
		}

		&-bottom {
			display: flex;
			justify-content: center;
			padding-bottom: 1.5rem;
		}
	}
}
</style>
