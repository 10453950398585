<template>
	<ul v-if="boxes.length > 0">
		<li
			class="result"
			v-for="device of boxes"
			data-testid="box-search-result"
			:key="`global_search_box_${device.deviceId}`"
			@click="getRouteBox(device)"
		>
			<div class="status">
				<div class="round active" v-if="getDeviceStatus(device) === 'ONLINE'" />
				<div
					class="round inactive"
					v-else-if="getDeviceStatus(device) === 'OFFLINE'"
				/>
				<div class="round notconnected" v-else />
			</div>
			<span>{{
				device.name || device.hardwareId || device.deviceId
			}}</span>
		</li>
	</ul>
	<ul v-else>
		<li>
			{{
				$t(
					"components.layouts.app.components.global-search.boxes-result.no-result"
				)
			}}
		</li>
	</ul>
</template>

<script>
import { landingRoute } from "@/helpers/router.helper.js";

export default {
	name: "BoxesResult",

	props: {
		boxes: {
			type: Array,
			default: () => []
		}
	},

	methods: {
		getDeviceStatus(device) {
			return device?.connectivityStatus?.connectionStatusInfo?.status;
		},

		getRouteBox(device) {
			this.$router.push({
				name: landingRoute(device),
				params: { id: device.deviceId }
			});
		}
	}
};
</script>

<style scoped lang="scss">
.result {
	cursor: pointer;
	&:hover {
		span {
			font-weight: $weight-semibold;
		}
	}
}
</style>
